import { GatsbySeo } from 'gatsby-plugin-next-seo'

import SustentabilidadeBrasilComponent from '../../components/Institucional/SustentabilidadeBrasilComponent'

function SustentabilidadeBrasil() {
  return (
    <>
      <GatsbySeo
        language="pt-br"
        title="Institucional | Decathlon"
        noindex
        nofollow
      />
      <SustentabilidadeBrasilComponent />
    </>
  )
}

export default SustentabilidadeBrasil
